<template>
    <Head :title="$t('create')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('Recruiters income') }}</div>
        </template>

        <template #end>
            <Fluid>
                <div class="grid grid-cols-3 gap-x-4 py-4">
                    <InputWrap class="min-w-36" label="Месяц">
                        <template v-slot="{id, invalid}">
                            <Select :inputId="id" :invalid="invalid" v-model="form.month" :options="months" optionLabel="name" optionValue="id" />
                        </template>
                    </InputWrap>
                    <InputWrap label="Год">
                        <template v-slot="{id, invalid}">
                            <Select :inputId="id" :invalid="invalid" v-model="form.year" :options="years" optionLabel="name" optionValue="id" />
                        </template>
                    </InputWrap>
                    <Button label="Применить" :disabled="form.processing" @click.prevent="submitForm"></Button>
                </div>
            </Fluid>
        </template>
    </Toolbar>

    <Fluid>
        <div class="flex mt-8">
            <div class="card flex flex-col gap-4 w-full">
                <DataTable :value="data" tableStyle="min-width: 50rem">
                    
                    <Column field="user" :header="$t('user.recruiter')">
                        <template #body="{ data, field }">
                            {{ data[field]['full_name'] }} <i v-if="data[field]['mentor']" class="pi pi-users ml-1 cursor-pointer" v-tooltip="data[field]['mentor']['full_name']"></i>
                        </template>
                    </Column>

                    <Column field="counters" :header="$t('user.summ')">
                        <template #body="{ data, field }">
                            <span class="cursor-default" v-tooltip="data['counters']['worked'] +' * '+ data['user']['worked_award'] +' + '+ data['counters']['padawan_worked'] +' * '+ data['user']['mentor_award']" >{{ data[field]['award'] }}</span>
                        </template>
                    </Column>

                    <Column field="counters" :header="$t('user.worked')">
                        <template #body="{ data, field }">
                            <template v-if="data[field]['worked_list'].length > 0 ">
                                <span class="cursor-pointer" @click.prevent="showLeadsList(data[field]['worked_list'])">{{ data[field]['worked'] }}</span>
                            </template>
                            <template v-else>
                                {{ data[field]['worked'] }}
                            </template>
                        </template>
                    </Column>

                    <Column field="user" :header="$t('user.award')">
                        <template #body="{ data, field }">
                            {{ data[field]['worked_award'] }}
                        </template>
                    </Column>

                    <Column field="counters" :header="$t('user.padawan_worked')">
                        <template #body="{ data, field }">
                            <template v-if="data[field]['padawan_worked_list'].length > 0 ">
                                <span class="cursor-pointer" @click.prevent="showLeadsList(data[field]['padawan_worked_list'])">{{ data[field]['padawan_worked'] }}</span>
                            </template>
                            <template v-else>
                                {{ data[field]['padawan_worked'] }}
                            </template>
                        </template>
                    </Column>

                    <Column field="user" :header="$t('user.mentor_award')">
                        <template #body="{ data, field }">
                            {{ data[field]['mentor_award'] }}
                        </template>
                    </Column>

                    <Column field="padawans" :header="$t('user.padawans')">
                        <template #body="{ data, field }">
                            <template v-if="data[field].length > 0 ">
                                <span class="cursor-pointer" @click.prevent="showUsersList(data[field])">{{ data[field].length }}</span>
                            </template>
                            <template v-else>
                                {{ data[field].length  }}
                            </template>
                        </template>
                    </Column>

                    <ColumnGroup type="footer">
                        <Row>
                            <Column footer="Итого:"/>
                            <Column :footer="columnSum('award')" />
                            <Column :footer="columnSum('worked')" />
                            <Column footer=""/>
                            <Column :footer="columnSum('padawan_worked')" />
                            <Column footer=""/>
                            <Column :footer="columnSum('padawans')" />
                        </Row>
                    </ColumnGroup>
                </DataTable>

                <Drawer v-model:visible="showDrawer" :blockScroll="true" :header="'Лиды'" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
                    <div class="card !p-2 flex flex-col flex-wrap">
                        <template v-if="darawerLeadsList.length > 0">
                            <template v-for="leadStat in darawerLeadsList">
                                <Link :href="route('lead.edit', leadStat.lead.id)">{{ leadStat.lead.full_name }}</Link>
                                <Divider />
                            </template>
                        </template>
                    </div>
                </Drawer>

                <Drawer v-model:visible="showUsersDrawer" :blockScroll="true" :header="'Подчиненные'" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
                    <div class="card !p-2 flex flex-col flex-wrap">
                        <template v-if="darawerUsersList.length > 0">
                            <template v-for="user in darawerUsersList">
                                <span>{{ user.full_name }}</span>
                                <Divider />
                            </template>
                        </template>
                    </div>
                </Drawer>
            </div>
        </div>
    </Fluid>
</template>

<script>
import { Head, router, Link, useForm} from '@inertiajs/vue3'
import PrimeLayout from '../../Layouts/PrimeLayout.vue';
import { InputWrap } from "../../Components/PrimeForm";
import { $t } from '@primevue/themes';

export default {
    layout: PrimeLayout,
    components: {
        Head, Link, InputWrap
    },
    props: {
        month: Number,
        year: Number,
        data: Object
    },
    data() {
        return {
            showDrawer: false,
            showUsersDrawer: false,
            darawerLeadsList: [],
            darawerUsersList: [],
            months: [
                {name: 'Январь', id: 1},
                {name: 'Февраль', id: 2},
                {name: 'Март', id: 3},
                {name: 'Апрель', id: 4},
                {name: 'Май', id: 5},
                {name: 'Июнь', id: 6},
                {name: 'Июль', id: 7},
                {name: 'Август', id: 8},
                {name: 'Сентябрь', id: 9},
                {name: 'Октябрь', id: 10},
                {name: 'Ноябрь', id: 11},
                {name: 'Декабрь', id: 12},
            ],
            years: [
                { name: '2024', id: 2024 },
                { name: '2025', id: 2025 },
            ]
        }
    },
    setup(props) {
        const form = useForm({
            month: props.month ?? null,
            year: props.year ?? null,
        });

        return { form };
    },
    watch: {},
    methods: {
        columnSum(column) {
            return String(this.data.reduce((total, item) => total + ( item.counters[column] ?? 0), 0));
        },
        showLeadsList(list) {
            this.showDrawer = list.length > 0 ? true :false;
            this.darawerLeadsList = list;
        },
        showUsersList(list) {
            this.showUsersDrawer = list.length > 0 ? true :false;
            this.darawerUsersList = list;
        },
        submitForm() {
            this.form.get(route('moderation.recruiters-income'));
        }
    },
}
</script>
<template>
    <div class="card !p-0">
        <div v-if="!loaded" class="flex w-full h-[80dvh] items-center">
            <ProgressSpinner class="m-auto !w-1/5 !h-1/5" strokeWidth="4" fill="transparent" animationDuration=".5s"/>
        </div>

        <DataTable 
            v-if="loaded"
            selectionMode="single"
            :editMode="editMode ?'cell':null" 
            @cell-edit-complete="onCellEditComplete"
            v-model:filters="filters"
            v-model:sortField="sortField"
            v-model:sortOrder="sortOrder"
            :value="lead_statistics.data" 
            paginator 
            showGridlines 
            scrollable
            scrollHeight="77dvh"
            :rows="lead_statistics.meta.per_page"
            :totalRecords="lead_statistics.meta.total" 
            :lazy="true"
            @filter="onFilter"
            @page="onPage"
            @sort="onSort"
            dataKey="id"
            filterDisplay="menu" 
            :loading="false" 
        >
            <template #header>
                <div class="flex justify-between">
                    <Button type="button" icon="pi pi-filter-slash" :label="$t('Clear')" outlined @click="clearFilter()" />
                    <IconField>
                        <InputIcon>
                            <i class="pi pi-search" />
                        </InputIcon>
                        <InputText v-model="filters['search'].value" :placeholder="$t('search')" />
                    </IconField>
                </div>
            </template>

            <Column field="full_name" :header="$t('leads.lead')">
                <template #body="{ data }">
                    <Link :href="route('lead.edit', data.lead_id)">{{ data.full_name }}</Link>
                </template>
            </Column>

            <Column field="recruiter.full_name" :header="$t('leads.recruiter')">
                <template #body="{ data }">
                    {{ data.recruiter.full_name }}
                </template>
            </Column>

            <Column field="recruited_date" :header="$t('leads.recruited_date')" sortField="recruited_date" :sortable="true" dataType="date">
                <template #body="{ data }">
                    {{ data.recruited_date }}
                </template>
                <template #editor="{ data, field }">
                    <Date v-if="data.work_status !== 'confirmed'" v-model="data.recruited_date" :showButtonBar="true"/>
                    <template v-else>{{ data.recruited_date }}</template>
                </template>

                <!--<template #filter="{ filterModel }">
                    <Date v-model="filterModel.value"/>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('recruited_date') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>-->
            </Column>

            <Column field="first_workday" :header="$t('leads.first_workday')" sortField="first_workday" :sortable="true" dataType="date">
                <template #body="{ data }">
                    {{ data.first_workday }}
                </template>
                <template #editor="{ data }">
                    <Date v-if="data.work_status !== 'confirmed'" v-model="data.first_workday" :showButtonBar="true"/>
                    <template v-else>{{ data.first_workday }}</template>
                </template>

                <!--<template #filter="{ filterModel }">
                    <Date v-model="filterModel.value"/>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('first_workday') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>-->
            </Column>

            <Column field="leave_date" :header="$t('leads.leave_date')" sortField="leave_date" :sortable="true" dataType="date">
                <template #body="{ data }">
                    {{ data.leave_date }}
                </template>
                <template #editor="{ data, field }">
                    <Date v-if="data.work_status !== 'confirmed'" v-model="data.leave_date" :showButtonBar="true"/>
                    <template v-else>{{ data.leave_date }}</template>
                </template>

                <!--<template #filter="{ filterModel }">
                    <Date v-model="filterModel.value"/>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('leave_date') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>-->
            </Column>

            <Column field="worked_days" :header="$t('leads.worked_days')" sortField="worked_days" filterField="worked_days" :sortable="true" dataType="numeric">
                <template #body="{ data }">
                    {{ data.worked_days }}
                </template>
                <template #editor="{ data }">
                    <InputNumber v-if="data.work_status !== 'confirmed' && data.leave_date === null" v-model="data.worked_days"/>
                    <template v-else>{{ data.worked_days }}</template>
                </template>

                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" :useGrouping="false" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('worked_days') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column field="required_workdays" :header="$t('leads.required_workdays')" sortField="required_workdays" filterField="required_workdays" :sortable="true" dataType="numeric">
                <template #body="{ data }">
                    {{ data.required_workdays }}
                </template>
                <template #editor="{ data }">
                    <InputNumber v-if="data.work_status !== 'confirmed'" v-model="data.required_workdays"/>
                    <template v-else>{{ data.required_workdays }}</template>
                </template>

                <template #filter="{ filterModel }">
                    <InputNumber v-model="filterModel.value" :useGrouping="false" />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('required_workdays') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column field="vacancy" :header="$t('leads.vacancy')" filterField="vacancy" :showFilterMatchModes="false" >
                <template #body="{ data }" >
                    <Link v-if="data.vacancy" :href="route('vacancy.edit', data.vacancy.id)">{{ data.vacancy.title }}</Link>
                </template>
                <template #filter="{ filterModel }">
                    <AutoComplete  
                        v-model="selectedVacancy" 
                        :suggestions="vacancies" 
                        optionLabel="title" 
                        optionValue="id" 
                        class=""
                        forceSelection
                        :loading="vacancySelectLoading"
                        @complete="fetchVacancies"  
                        fluid
                        :placeholder="$t('leads.vacancy')"
                    />
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('vacancy') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
                <template #filterclear="{ filterCallback }">
                    <Button type="button" @click="vacancyFilterClear(filterCallback)" outlined size="small" :label="$t('Clear')"></Button>
                </template>
            </Column>

            <Column field="work_status" :header="$t('leads.status')" filterField="work_status" :showFilterMatchModes="false">
                <template #body="{ data }">
                    <span v-if="data.work_status == 'not_come'" class="cursor-pointer material-symbols-outlined text-[#991b1b]" v-tooltip="$t('leads.work_status_not_come')">event_busy</span>
                    <span v-if="data.work_status == 'fired'" class="cursor-pointer material-symbols-outlined text-[#dc2626]" v-tooltip="$t('leads.work_status_fired')">person_off</span>
                    <span v-if="data.work_status == 'worked'" class="cursor-pointer material-symbols-outlined text-[#ca8a04]" v-tooltip="$t('leads.work_status_worked')">work_alert</span>
                    <i v-if="data.work_status == 'confirmed'" class="!text-2xl pi pi-check-circle cursor-pointer text-[#16a34a]" v-tooltip="$t('leads.work_status_confirmed')"></i>
                    <span v-if="data.work_status == 'working'" class="cursor-pointer material-symbols-outlined text-[#2563eb]" v-tooltip="$t('leads.work_status_working')">work</span>
                </template>

                <template #filter="{ filterModel }">
                    <MultiSelect v-model="filterModel.value" :options="workStatuses" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                        <template #option="slotProps">
                            {{ slotProps.option?.name }}
                        </template>
                    </MultiSelect>
                </template>
                <template #filtericon>
                    <i :class="isFilterActive('work_status') ? 'pi pi-filter-fill' : 'pi pi-filter'"></i>
                </template>
            </Column>

            <Column field="action" style="min-width: 7rem">
                <template #body="{ data, field }">
                    <Button v-if="data.work_status === 'worked'"  @click.stop.prevent="handleConfirm(data.id)" aria-label="Подтвердить отработку" severity="success">
                        <template #icon>
                            <i class="!text-2xl pi pi-check-circle cursor-pointer"></i>
                        </template>
                    </Button>
                    <Button v-else-if="data.work_status === 'confirmed'" @click.stop.prevent="handleCancel(data.id)" aria-label="Отменить отработку" severity="danger"> 
                        <template #icon>
                            <span class="cursor-pointer material-symbols-outlined">cancel</span>
                        </template>
                    </Button>
                    <ButtonGroup v-else-if="data.work_status !== 'not_come'">
                        <Button @click.stop.prevent="handleNotWorking(data.id)" v-tooltip.top="$t('leads.work_not_started')" severity="danger" >
                            <template #icon>
                                <span class="cursor-pointer material-symbols-outlined">person_off</span>
                            </template>
                        </Button>
                    </ButtonGroup>
                </template>
            </Column>

        </DataTable>
    </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from '@primevue/core/api';
import { queryToFilter, filterToQuery } from '../Composables/dtFilterAdapters';
import { router, Link, useForm } from '@inertiajs/vue3';
import { trans } from 'laravel-vue-i18n';
import { usePermissions } from '../Composables/usePermissions';
import { Date } from "../Components/PrimeForm";
import { useToast } from 'vue-toastification';

export default {
    components: { Link, Date },
    props: {
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        filterLogic: {
            type: Object,
            required: false,
            default: {}
        },
        sortLogic: {
            type: String,
            required: false,
            default: null
        },
        lead_statistics: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            required: false,
            default: false
        },
        errors: Object
    },
    setup(props) {
        
        const defaultFilters = {
            search: { value: null, matchMode: FilterMatchMode.EQUALS },
            work_status: { value: null, matchMode: FilterMatchMode.IN },
            vacancy: { value: null, matchMode: FilterMatchMode.IN },
            worked_days: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            required_workdays: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            leave_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            first_workday: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
            recruited_date: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
        }

        const { hasRole, hasPermission } = usePermissions();
        const toast = useToast();

        return { defaultFilters, hasRole, hasPermission, toast };
    },
    data() {
        return {
            filters: null,
            sortField: null,
            sortOrder: null,
            loaded: false,
            fieldLoading: [],
            vacancies: null,
            selectedVacancy: null,
            vacancySelectLoading: false,
            workStatuses: [
                {
                    id: 'working',
                    name: 'Работает',
                },
                {
                    id: 'confirmed',
                    name: 'Подтвержден',
                },
                {
                    id: 'worked',
                    name: 'Отработал',
                },
                {
                    id: 'fired',
                    name: 'Уволен',
                },
                {
                    id: 'not_come',
                    name: 'Не вышел',
                },
            ]
        };
    },
    created() {
        this.initFilters();
        this.initSort();
    },
    mounted() {
        setTimeout(() => {
            this.loaded = true;
        }, 1);
    },
    computed:{},
    methods: {
        handleConfirm(id){
            router.post(route('moderation.leads.confirm', id),
            {
                preserveScroll: true,
                preserveState: true,
            });
        },
        handleCancel(id){
            router.post(route('moderation.leads.cancel', id),
            {
                preserveScroll: true,
                preserveState: true,
            });
        },
        handleNotWorking(id){
            router.post(route('moderation.leads.not_working', id),
            {
                preserveScroll: true,
                preserveState: true,
            });
        },
        onRowContextMenu(event) {
            if(this.menuModel.length > 0){
                this.$refs.cm.show(event.originalEvent);
            }else{
                return false;
            }
        },
        destroy(vacancy) {
            this.$emit('destroy', vacancy);
        },
        onCellEditComplete(event) {
            const field = event.field;
            const newValue = event.newValue;
            const value = event.value;
            const index = event.index;
            
            if (value != newValue) {
                this.lead_statistics.data[index][field] = newValue;
                this.save(index, field);
            }
        },
        isLoading(field, index) {
            if (this.fieldLoading && Array.isArray(this.fieldLoading)) {
                const found = this.fieldLoading.findIndex(x => (x.index == index && x.field == field));
                return found != -1;
            }
        },
        save(index, field){
            const stat = this.lead_statistics.data[index];

            if(stat){
                const form = useForm({
                    id: stat.id,
                    worked_days: stat.worked_days,
                    required_workdays: stat.required_workdays,
                    recruited_date: stat.recruited_date,
                    first_workday: stat.first_workday,
                    leave_date: stat.leave_date,
                    worked_confirmed: stat.worked_confirmed,
                    dirty_field: field
                });
                
                form.post(route('moderation.leads.update', stat.id),
                    {
                        onError: (errors) => {
                            for (const key in errors) {
                                if (errors.hasOwnProperty(key)) {
                                    this.toast.error(errors[key], {timeout: 3500});
                                }
                            }
                        },
                    }
                );
            }
        },
        onFilter(event = null) {
            let filters = this.filters;
            if (event)
                filters = event.filters;
            this.$emit('filter', filterToQuery(filters));
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        initFilters() {
            this.filters = queryToFilter(this.defaultFilters, this.filterData, this.filterLogic);
        },
        initSort() {
            if (this.sortLogic) {
                if (this.sortLogic.startsWith('-')) {
                    this.sortField = this.sortLogic.replace('-', '');
                    this.sortOrder = -1;
                }
                else {
                    this.sortField = this.sortLogic;
                    this.sortOrder = 1;
                }
            }
        },
        isFilterActive(key) {
            let res = false;
            if (this.filters.hasOwnProperty(key)) {
                if (this.filters[key].hasOwnProperty('value')) {
                    return this.filters[key]['value'] !== null;
                }
                else if (this.filters[key].hasOwnProperty('constraints')) {
                    this.filters[key].constraints.forEach((single) => {
                        if (single.value !== null) {
                            res = true;
                            return;
                        }
                    });
                }
            }
            return res;
        },
        fetchVacancies(event) {
            this.vacancySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.filters.vacancy.value,
                    attribute: 'title',
                    related_model: 'App\\Models\\Vacancy',
                    resource: 'App\\Http\\Resources\\VacancyResource',
                })
                .then(response => {
                    this.vacancies = response.data.data;
                    this.vacancySelectLoading = false;
                });
        },
        vacancyFilterClear(filterCallback) {
            filterCallback();
            this.selectedVacancy= null;
            this.filters.vacancy.value = null;
            this.onFilter();
        }
        /*saveRow(index)
        {
            let lead_stat = this.lead_statistics.data[index];
            const form = useForm({
                first_workday: lead_stat.first_workday,
                leave_date: lead_stat.leave_date,
                recruited_date: lead_stat.recruited_date,
                required_workdays: lead_stat.required_workdays,
                worked_confirmed: lead_stat.worked_confirmed,
            });

            this.form
                .transform((data) => (this.prepareItem(data)))
                .post(route('vacancy.update', this.vacancy.id));
        }*/
    },
    watch: {
        'filters.search.value': function (val) {
            this.onFilter();
        },
        selectedVacancy(vacancy) {
            if (vacancy && vacancy.hasOwnProperty('id')) {
                this.filters.vacancy.value = vacancy.id;
            }
            else {
                this.filters.vacancy.value = null;
            }
        }
    }
};
</script>
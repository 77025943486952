<template>
    <Head :title="$t('leads.moderation_title')"/>

    <Toolbar class="mb-4 !border-none">
        <template #start id="tollbar-start">
            <div class="font-medium text-xl">{{ $t('leads.moderation_title') }}</div>
            <!-- <SelectButton v-if="!mobile" v-model="viewType" :options="views" optionLabel="value" optionValue="value" :allowEmpty="false" aria-labelledby="custom" class="ms-4">
                <template #option="slotProps">
                    <i class="pi" :class="slotProps.option.icon" v-tooltip.top="$t(slotProps.option.value)"></i>
                </template>
            </SelectButton> -->
            <ToggleButton v-if="viewType == 'table'" v-model="editMode" pt:label:class="hidden" onIcon="pi pi-lock-open" offIcon="pi pi-lock" class="ms-2" />
            <Button icon="pi pi-search" severity="secondary" class="md:!hidden" text rounded @click="searchDrawer = !searchDrawer"/>
        </template>

        <template #center>
            <IconField class="!hidden md:!block" v-if="viewType == 'cards'">
                <InputIcon>
                    <i class="pi pi-search" />
                </InputIcon>
                <InputText :placeholder="$t('search')" v-model="filterData.search"/>
            </IconField>
        </template>

        <template #end>
            <Button v-if="viewType == 'cards'" icon="pi pi-filter" severity="secondary" class="me-2" @click="filterDrawer = !filterDrawer" outlined/>
        </template>
    </Toolbar>

    <div v-if="viewType == 'cards'" class="card !bg-[var(--surface-ground)] !p-0">
        <!--<div class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6">
            <LeadStatisticCard v-for="lead_statistic in lead_statistics.data" :key="lead_statistic.id" :lead_statistic="lead_statistic"></LeadStatisticCard>
        </div>
        <Paginator class="mt-4" pt:root:class="!bg-[var(--surface-ground)]" :rows="lead_statistics.meta.per_page" :totalRecords="lead_statistics.meta.total" @page="switchPage" :alwaysShow="false"></Paginator>
        <LeadStatisticFilter 
            v-model:active="filterDrawer" 
            @filter="onTableFilter"
            @sort="onTableSort"
            :filterData="filterData"
            :filterLogic="filterLogic"
        />-->
    </div>

    <LeadStatisticTable 
        v-else
        @page="switchPage" 
        @filter="onTableFilter"
        @sort="onTableSort"
        @destroy="tableDestroy"
        :filterData="filterData"
        :filterLogic="filterLogic" 
        :sortLogic="sortLogic"
        :lead_statistics="lead_statistics" 
        :editMode="editMode"
    />
</template>

<script>
import { ref } from 'vue';
import { breakpointsTailwind, useBreakpoints } from '@vueuse/core';
import { Link, Head, router } from "@inertiajs/vue3";
import PrimeFullHeightLayout from "../../Layouts/PrimeFullHeightLayout.vue";
import LeadStatisticCard from "../../Partials/LeadStatisticCard.vue";
import LeadStatisticTable from "../../Partials/LeadStatisticTable.vue";
import LeadStatisticFilter from "../../Components/LeadStatisticFilter.vue";
import { usePermissions } from '../../Composables/usePermissions';
import { useConfirm } from 'primevue/useconfirm';

export default {
    layout: PrimeFullHeightLayout,
    components: {
        Link, Head, LeadStatisticCard, LeadStatisticFilter, LeadStatisticTable
    },
    props: {
        filter: Object,
        filterLogic: Object,
        sort: String,
        lead_statistics: Object,
        errors: Object
    },
    setup(props) {
        const filterData = ref({
            search: props.filter?.search ?? null,
            vacancy: props.filter?.vacancy,
            work_status: props.filter?.work_status,
            worked_days: props.filter?.worked_days,
            required_workdays: props.filter?.required_workdays,
            leave_date: props.filter?.leave_date,
            first_workday: props.filter?.first_workday,
            recruited_date: props.filter?.recruited_date,
        });

        const filterLogic = ref(props.filterLogic ?? {});
        const sortLogic = ref(props.sort ?? null);

        const breakpoints = useBreakpoints(breakpointsTailwind);
        const mobile = breakpoints.smallerOrEqual('md');

        let viewType = localStorage.leadStatisticsView ?? 'table';

        const views = [
            {
                value: 'table',
                icon: 'pi-table',
            },
            {
                value: 'cards',
                icon: 'pi-objects-column',
            },
        ];
        
        if (mobile.value) {
            viewType = 'cards';
        }
        viewType = ref(viewType);

        const { hasRole, hasPermission } = usePermissions();
        const confirmPopup = useConfirm();
      
        return {
            hasRole,
            hasPermission,
            confirmPopup,
            filterData,
            filterLogic,
            sortLogic,
            views,
            mobile,
            viewType,
        };
    },
    data() {
        return {
            editMode: true,
            filterDrawer: false,
            searchDrawer: false,
            drawerSearchValue: this.filterData.search
        }
    },
    created() {
        this.searchStart = _.debounce((vm, text) => {
            if (text && text.length >= 3) {
                vm.queries['search'] = text;
            } else if(vm.queries['search']){
                Reflect.deleteProperty(vm.queries, 'search');
            }
        }, 1200);
    },
    methods: {
        switchPage(event) {
            const page = event.page + 1;
            this.fetch(page);
        },
        onTableFilter(filter) {
            if (filter) {
                this.fetch(false, filter);
            }
            else
                router.get(this.lead_statistics.meta.path);
        },
        onTableSort(event) {
            const sortField = event.sortField;
            if (sortField) {
                const sortOrder = event.sortOrder == -1 ? '-' : '';
                const sort = sortOrder + sortField;
                this.sortLogic = sort;
                this.fetch(false, null, sort);
            }
        },
        fetch(page=false, filter=null, sort=null){
            let pageUrl = this.lead_statistics.meta.path + `?page=${this.lead_statistics.meta.current_page}`;
            if (page)
                pageUrl = this.lead_statistics.meta.path + `?page=${page}`;

            let logic = null;
            if (filter && filter.hasOwnProperty('logic')) {
                logic = filter.logic;
                filter = filter.query;
                this.filterData = filter;
                this.filterLogic = logic;
            }

            let query = {
                filter: this.makeFilterQuery(filter ?? this.filterData),
                filterLogic: logic ?? this.filterLogic,
            }

            sort = sort ?? this.sortLogic;
            if (sort) {
                query.sort = sort;
            }

            router.get(
                pageUrl, 
                query, 
                { 
                    preserveScroll: true, 
                    preserveState: true,
                    onBefore: (visit) => {this.loading = true;},
                    onFinish: (visit) => {this.loading = false;}
                }
            );
        },
        makeFilterQuery(filterValues) {
            console.log(filterValues);
            let query = {};
            Object.keys(filterValues).forEach((key)=>{
                const value = filterValues[key];
                if (value !== null && value !== undefined){
                    query[key] = filterValues[key];
                }
            });
            
            return query;
        },
        tableDestroy(id) {
            this.confirmPopup.require({
                header: 'Удаление',
                message: 'Подтвердить удаление?',
                icon: 'pi pi-info-circle',
                rejectProps: {
                    label: 'Отменить',
                    severity: 'secondary',
                    outlined: true
                },
                acceptProps: {
                    label: 'Да',
                    severity: 'danger'
                },
                accept: () => {
                    router.delete(route('vacancy.destroy', id));
                },
            });
        },
        mobileSearchHandle(closeCallback) {
            this.filterData.search = this.drawerSearchValue;
            closeCallback();
        },
        mobileSearchClear(closeCallback) {
            this.drawerSearchValue = null;
            this.filterData.search = null;
            closeCallback();
        },
    },
    watch: {
        'filterData.search': function(val) {
            this.fetch(false);
        },
        viewType(value) {
            localStorage.leadStatisticsView = value;
            if (value == 'cards')
                this.editMode = false;
        }
    }
}
</script>
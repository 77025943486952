<template>
    <Drawer v-model:visible="visible" :blockScroll="true" :header="$t('filter')" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <div class="card !p-2 flex flex-col flex-wrap">
           
        </div>
        
        <template #footer>
            <div class="flex items-center gap-2">
                <Button :label="$t('apply')" class="flex-auto" @click.stop="onFilter"></Button>
                <Button :label="$t('clear')" class="flex-auto" outlined @click.stop="clearFilter"></Button>
            </div>
        </template>
    </Drawer>
</template>

<script>
import { ref } from "vue";

export default {
    components: {},
    props: {
        active: {
            type: Boolean,
            required: true
        },
        filterSliders: {
            type: Object,
            required: false,
            default: []
        },
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        filterLogic: {
            type: Object,
            required: false,
            default: {}
        }
    },
    setup(props) {

    },
    data() {
        return {};
    },
    created() {
        this.filters = this.queryToFilter(this.filterData);
    },
    methods: {
        onFilter() {
            const filter = this.filterToQuery(this.filters);
            this.$emit('filter', filter);
            this.visible = false;
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        filterToQuery(filters) {
            filters = _.cloneDeep(filters);
            for (let [key, params] of Object.entries(this.filterSliders)) {
                const values = filters[key];
                if (values && values?.length == 2 && values[0] && values[1]) {
                    filters[key][0] = '>=' + values[0];
                    filters[key][1] = '<=' + values[1];
                } 
            }

            return filters;
        },
        queryToFilter(filters) {
            filters = _.cloneDeep(filters);
            //Костыль для переключения между типами отображения с отфильтрованными результатами
            for (let [key, params] of Object.entries(this.filterSliders)) {
                const values = filters[key];
                if (values) {
                    filters[key] = [];
                }
            }

            for (let [key, value] of Object.entries(filters)) {
                filters[key] = this.convertFilterValue(value);
            }
            return filters;
        },
        convertFilterValue(value) {
            //Для множественных значений простого фильтра
            if (Array.isArray(value)) {
                let result = [];
                value.forEach((single) => {
                    single = this.convertFilterValue(single);
                    result.push(single);
                });
                return result;
            }

            if (value == 'true')
                return true;
            if (value == 'false')
                return false;

            if (typeof value == 'string') {
                value = value.replace('<=', '');
                value = value.replace('>=', '');
            }

            const parsedNumber = parseFloat(value);
            if (!isNaN(parsedNumber))
                return parsedNumber;
            return value;
        }
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        }
    },
    watch: {}
}
</script>
<template>
    <Drawer v-model:visible="visible" :blockScroll="true" :header="$t('filter')" position="right" class="!w-full md:!w-1/2 lg:!w-1/2 xl:!w-[34rem]">
        <div class="card !p-2 flex flex-col flex-wrap">
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.allowed_genders') }}</span>
                <SelectButton v-model="filters.allowed_genders" multiple :options="genders" optionLabel="icon" optionValue="value" dataKey="value" aria-labelledby="custom" class="justify-center w-full md:justify-start md:w-auto">
                    <template #option="slotProps">
                        <span :class="slotProps.option?.class">{{ slotProps.option?.icon }}</span>
                    </template>
                </SelectButton>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.requirements') }}</span>
                <SelectButtonComplex :options="requirements" v-model="filters.requirements"/>
            </div>


            <div class="flex flex-col gap-1 mb-6" v-if="hasPermission('edit-vacancy')">
                <span class="block font-bold">{{ $t('vacancies.company') }}</span>
                <MultiSelect v-model="filters.company" :options="companies" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        <div class="flex items-center gap-2">
                            <img v-if="slotProps.option?.logo" :alt="slotProps.option?.company" :src="slotProps.option?.logo" style="width: 32px" />
                            <span>{{ slotProps.option?.name }}</span>
                        </div>
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.facility') }}</span>
                <MultiSelect v-model="filters.facilities" :options="facilities" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.name }}
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.contract_type') }}</span>
                <MultiSelect v-model="filters.contract_type" :options="contractTypes" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.name }}
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.housing_type') }}</span>
                <MultiSelect v-model="filters.housing_type" :options="housingTypes" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        {{ slotProps.option?.name }}
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.citizenships') }}</span>
                <MultiSelect v-model="filters.citizenship" :options="citizenships" optionValue="id" optionLabel="name" :placeholder="$t('Any')">
                    <template #option="slotProps">
                        <div class="flex items-center gap-2">
                            <img alt="flag" src="/img/flag_placeholder.png" :class="`flag flag-${slotProps.option?.code}`" style="width: 24px" />
                            <span>{{ slotProps.option?.name }}</span>
                        </div>
                    </template>
                </MultiSelect>
            </div>
            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.work_city') }}</span>
                <AutoComplete  
                    v-model="selectedWorkCity" 
                    :suggestions="cities" 
                    optionLabel="full_name" 
                    optionValue="id" 
                    forceSelection
                    :loading="citySelectLoading"
                    @complete="fetchCities"  
                    :placeholder="$t('City')"
                    fluid
                />
            </div>

            <div class="flex flex-col gap-1 mb-6">
                <span class="block font-bold">{{ $t('vacancies.closest_city') }}</span>
                <div class="grid grid-cols-3 grid-rows-1 gap-2">
                    <AutoComplete  
                        v-model="selectedClosestCity" 
                        :suggestions="cities" 
                        optionLabel="full_name" 
                        optionValue="id" 
                        class="col-span-2"
                        forceSelection
                        :loading="citySelectLoading"
                        @complete="fetchCities"  
                        :placeholder="$t('City')"
                        fluid
                    />
                    <InputNumber v-model="filters.closest_city_km" :useGrouping="false" :placeholder="'km'" fluid/>
                </div>
            </div>
            <FilterSlider v-for="(params, key) in filterSliders" :key="key" :filter-key="key" :params="params" v-model="filters[key]"/>
        </div>
        
        <template #footer>
            <div class="flex items-center gap-2">
                <Button :label="$t('apply')" class="flex-auto" @click.stop="onFilter"></Button>
                <Button :label="$t('clear')" class="flex-auto" outlined @click.stop="clearFilter"></Button>
            </div>
        </template>
    </Drawer>
</template>

<script>
import { ref } from "vue";
import SelectButtonComplex from "./SelectButtonComplex.vue";
import FilterSlider from "./FilterSlider.vue";
import { usePermissions } from '../Composables/usePermissions';

export default {
    components: {
        SelectButtonComplex,
        FilterSlider
    },
    props: {
        active: {
            type: Boolean,
            required: true
        },
        filterSliders: {
            type: Object,
            required: false,
            default: []
        },
        filterData: {
            type: Object,
            required: false,
            default: {}
        },
        filterLogic: {
            type: Object,
            required: false,
            default: {}
        },
        companies: {
            type: Array,
            required: true
        },
        facilities: {
            type: Array,
            required: true
        },
        citizenships: {
            type: Array,
            required: true
        },
        genders: {
            type: Array,
            required: true
        },
        requirements: {
            type: Array,
            required: true
        },
        contractTypes: {
            type: Array,
            required: true
        },
        housingTypes: {
            type: Array,
            required: true
        },
    },
    setup(props) {
        const { hasRole, hasPermission } = usePermissions();

        return { hasRole, hasPermission };
    },
    data() {
        return {
            cities: [],
            filters: null,
            citySelectLoading: false,
            selectedClosestCity: null,
            selectedWorkCity: null,
        };
    },
    created() {
        this.filters = this.queryToFilter(this.filterData);
    },
    methods: {
        onFilter() {
            const filter = this.filterToQuery(this.filters);
            this.$emit('filter', filter);
            this.visible = false;
        },
        onPage(event) {
            this.$emit('page', event);
        },
        onSort(event) {
            this.$emit('sort', event);
        },
        clearFilter() {
            this.$emit('filter', null);
        },
        filterToQuery(filters) {
            filters = _.cloneDeep(filters);
            for (let [key, params] of Object.entries(this.filterSliders)) {
                const values = filters[key];
                if (values && values?.length == 2 && values[0] && values[1]) {
                    filters[key][0] = '>=' + values[0];
                    filters[key][1] = '<=' + values[1];
                } 
            }

            return filters;
        },
        queryToFilter(filters) {
            filters = _.cloneDeep(filters);
            //Костыль для переключения между типами отображения с отфильтрованными результатами
            for (let [key, params] of Object.entries(this.filterSliders)) {
                const values = filters[key];
                if (values) {
                    filters[key] = [];
                }
            }

            for (let [key, value] of Object.entries(filters)) {
                filters[key] = this.convertFilterValue(value);
            }
            return filters;
        },
        fetchCities(event) {
            this.citySelectLoading = true;

            axios
                .post('/ajax/select2', {
                    q: event.query,
                    selected: this.filters.closest_city,
                    attribute: 'full_name',
                    related_model: 'App\\Models\\City',
                    resource: 'App\\Http\\Resources\\CityResource',
                })
                .then(response => {
                    this.cities = response.data.data;
                    this.citySelectLoading = false;
                });
        },
        convertFilterValue(value) {
            //Для множественных значений простого фильтра
            if (Array.isArray(value)) {
                let result = [];
                value.forEach((single) => {
                    single = this.convertFilterValue(single);
                    result.push(single);
                });
                return result;
            }

            if (value == 'true')
                return true;
            if (value == 'false')
                return false;

            if (typeof value == 'string') {
                value = value.replace('<=', '');
                value = value.replace('>=', '');
            }

            const parsedNumber = parseFloat(value);
            if (!isNaN(parsedNumber))
                return parsedNumber;
            return value;
        }
    },
    computed: {
        visible: {
            get() {
                return this.active;
            },
            set(val) {
                this.$emit('update:active', val);
            }
        }
    },
    watch: {
        selectedClosestCity(city) {
            if (city && city.hasOwnProperty('id')) {
                this.filters.closest_city = city.id;
            }
            else {
                this.filters.closest_city = null;
            }
        },
        selectedWorkCity(city) {
            if (city && city.hasOwnProperty('id')) {
                this.filters.work_city = city.id;
            }
            else {
                this.filters.work_city = null;
            }
        }
    }
}
</script>
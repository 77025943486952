<template>
    <div class="card border flex flex-col !p-0 !mb-0">
        {{ lead_statistic.id }}
    </div>
</template>

<script>
export default {
    props: {
        lead_statistic: {
            type: Object,
            required: true
        }
    },
    setup() {},
    methods: {}
}
</script>